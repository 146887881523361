import React, { useState } from "react";
import { renderHtmlWithTeX } from "../utils";

const SatQuestionValidator = () => {
  const [jsonInput, setJsonInput] = useState("");
  const [questionData, setQuestionData] = useState(null);
  const [error, setError] = useState(null);

const isValidHtml = (str) => {
    const div = document.createElement("div");
    div.innerHTML = str;
    const sanitizedInput = div.innerHTML;
    
    const wrapper = document.createElement("div");
    wrapper.innerHTML = str;
    
    return wrapper.innerHTML === sanitizedInput;
};

  const handleJsonChange = (e) => {
    setJsonInput(e.target.value);
  };

  const handleValidate = () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      setQuestionData(parsedData);
      setError(null);
    } catch (err) {
      setError("Invalid JSON format. Please enter valid JSON.");
      setQuestionData(null);
    }
  };
  
  return (
    <div className="px-10 py-10">
      <div>
        <textarea
          className="w-full p-2 border rounded-md"
          rows="6"
          placeholder="Enter JSON here..."
          value={jsonInput}
          onChange={handleJsonChange}
        />
        <button
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
          onClick={handleValidate}
        >
          Validate
        </button>
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {questionData && (
        <div className="py-6 px-3 flex flex-col rounded-md border mt-4">
          {questionData._id && (
            <p>
              <span className="font-semibold text-gray-800">Question Id:</span> {questionData._id}
            </p>
          )}
          <div className="flex w-full h-96 overflow-y-auto border rounded-lg">
            <div className="w-1/2 p-5 border-r-2">
              <p className="font-semibold text-gray-800">Description:</p>
              {questionData.description.trim() !== "" ? (isValidHtml(questionData.description) ? (
                renderHtmlWithTeX(questionData.description)
              ) : (
                <p className="text-red-500">Invalid HTML in Description</p>
              )) : (<p className="text-red-500">No description</p>) }
            </div>
            <div className="w-1/2 p-5">
              <p className="font-semibold text-gray-800">Question:</p>
              {questionData.question && isValidHtml(questionData.question) ? (
                renderHtmlWithTeX(questionData.question)
              ) : (
                <p className="text-red-500">Invalid HTML in Question</p>
              )}
              {questionData.answers?.length > 0 && (
                <div className="mt-2 space-y-2">
                  {questionData.answers.map((opt) => (
                    <div
                      key={opt.answerOption}
                      className={`flex items-center border rounded-md w-full p-1 ${opt.isCorrectAnswer ? 'bg-green-100' : ''}`}
                    >
                      <span className="font-semibold text-gray-800">
                        {opt.answerOption}
                      </span>
                      <span className="ml-2">
                        {isValidHtml(opt.answerPlaceHolder) ? (
                          renderHtmlWithTeX(opt.answerPlaceHolder)
                        ) : (
                          <span className="text-red-500">Invalid HTML in Option</span>
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="mt-2 w-full h-60 p-5 overflow-y-auto border rounded-lg">
            <span className="font-semibold text-gray-800">Explanation:</span>
            {questionData.explanation && isValidHtml(questionData.explanation) ? (
              renderHtmlWithTeX(questionData.explanation)
            ) : (
              <p className="text-red-500">Invalid HTML in Explanation</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SatQuestionValidator;
