import TeX from "@matejmazur/react-katex";
import React from "react";

export const renderHtmlWithTeX = (question) => {
  const voidElements = new Set([
    'area', 'base', 'br', 'col', 'embed', 'hr',
    'img', 'input', 'link', 'meta', 'param', 'source',
    'track', 'wbr'
  ]);

  const processNode = (node, keyPrefix = '') => {
    if (node.nodeType === Node.TEXT_NODE) {
      const parts = node.textContent.split(/\$~/);
      return parts.map((part, index) => {
        if (index % 2 === 1) {
          return <TeX key={`${keyPrefix}-${index}`}>{part}</TeX>;
        }
        return part.split('\n').map((text, i) => (
          <React.Fragment key={`${keyPrefix}-${index}-${i}`}>
            {i > 0 && <br />}
            {text}
          </React.Fragment>
        ));
      });
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
      const tagName = node.tagName.toLowerCase();
      const isVoid = voidElements.has(tagName);

      const children = isVoid
        ? null
        : Array.from(node.childNodes)
            .map((child, index) => processNode(child, `${keyPrefix}-${index}`))
            .flat();

      const getClassName = () => {
        if (tagName === 'ul') return 'list-disc pl-4';
        if (tagName === 'ol') return 'list-decimal pl-4';
        return undefined;
      };

      return React.createElement(
        tagName,
        {
          key: keyPrefix,
          className: getClassName(),
          ...(isVoid && { children: undefined })
        },
        children
      );
    }

    return null;
  };

  const doc = new DOMParser().parseFromString(question, 'text/html');
  return Array.from(doc.body.childNodes).map((node, index) =>
    processNode(node, `node-${index}`)
  );
};