import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useRef } from "react";
import { IoIosArrowForward, IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import axios from "axios";
import satReadingStore from "../../Services/Stores/satReadingStore";
import "katex/dist/katex.min.css";
import UploadImage from "./renderUploadImage";
import { GetFile, upload_file_to_s3 } from "../Common/FileUpload";
import { GrView } from "react-icons/gr";
import { RotatingLines } from "react-loader-spinner";
import ErrorPage from "../Common/ErrorPage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { renderHtmlWithTeX } from "../../utils";
import Modal from "../Common/Modal";


const MultipleChoiceQuestion = observer(() => {
  const navigate = useNavigate();
  const {
    currentModuleName,
    currentSectionName,
    questionType,
    currentModuleId,
    currentSectionId,
    questionCategory,
    setSectionId,
    setModuleId,
  } = satReadingStore;
  const { id } = useParams();
  const inputRef = useRef();
  const [options, setOptions] = useState([]);
  const [value, setValue] = React.useState("");
  const [questionTitleImages, setQuestionTitleImages] = useState([]);
  const [questionDescriptionImages, setQuestionDescriptionImages] = useState(
    []
  );
  const [questionTypesReading, setQuestionTypesReading] = useState([]);
  const [selectedReadingQuestionType, setSelectedReadingQuestionType] =
    useState({});
  const [questionOptionImages, setQuestionOptionImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorData, setErrorData] = useState({
    statusCode: "",
    errorMessage: "",
  });
  const [imageUrls,setImageUrls] = useState([]);
  const [isUpdate,setIsUpdate] = useState(false)
  const [isView,setIsView] = useState(false);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

    const modules = {
      toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: [] }, { background: [] }], // Text color and background color
        [{ align: [] }],
        ["clean"],
      ],
    };

    const formats = [
      "header",
      "font",
      "list",
      "bullet",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "color",
      "background",
      "align",
      "link",
      "image",
    ];

  const [question, setQuestion] = useState({
    question: "",
    description: "",
    answers: [],
    explanation: "",
    isActive: true,
    more: {},
    additionalProps: {},
    moduleId: currentModuleId,
    sectionId: currentSectionId,
    questionTypeId: "",
    questionContentTypeId: "",
    questionDifficultyLevel: "",
    imageDetails: {},
    questionTags: [],
  });

  const onChooseFile = () => {
    inputRef.current.click();
  };

  const addOptionImage = (option) => {
    let optionsCopy = [...options];

    optionsCopy.forEach((itm) => {
      if (itm.id === option.id) {
        if (!itm.answerPlaceHolder.includes(`{${option.answerOption} Image}`)) {
          itm.answerPlaceHolder =
            itm.answerPlaceHolder + `{${option.answerOption} Image}`;
          itm.optionImage = `${option.answerOption} Image`;
          itm.uploadedImage = "";
          itm.isEdit = false;
        }
      }
    });
    setOptions(optionsCopy);
  };
  const handleOptionsImage = async (event, option) => {
    const fileName = event.target.files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb
    if (fileName?.size > maxSizeInBytes) {
      toast.error("Image size should be less than 10MB");
      return
    }
    let public_url;
    try {
      const fileName = event.target.files[0];
      const s3Data = await upload_file_to_s3(fileName);
      public_url = s3Data?.data;
      if (public_url) {
        setQuestionOptionImages((prevImages) => {
          const existingIndex = prevImages.findIndex(
            (img) => Object.keys(img)[0] === option.optionImage
          );
          if (existingIndex !== -1) {
            return prevImages.map((image, index) =>
              index === existingIndex
                ? { [option.optionImage]: public_url }
                : image
            );
          }
          return [...prevImages, { [option.optionImage]: public_url }];
        });
        setOptions(
          options.map((optn) =>
            optn.optionImage === option.optionImage
              ? { ...optn, uploadedImage: public_url }
              : { ...optn, isCorrectAnswer: false }
          )
        );
      }
      const file_url = await GetFile(s3Data?.data?.fileName);
      let imageData= {
        id:option.id,
        imageUrl: file_url
      }
      let images = [...imageUrls];
      let existingIndex = images.findIndex((image) => image.id ===  option.id);
      if(existingIndex === -1){
        images.push(imageData);
      }else{
        images[existingIndex] = imageData;
      }
      setImageUrls(images);
    } catch (e) {
      console.log(e, "ERROR");
    }
  };

  const addOption = () => {
    const newOption = {
      id: options.length + 1,
      value: "",
      isActive: true,
      answerOption: `Option ${String.fromCharCode(options.length + 65)}`,
      isCorrectAnswer: false,
      correctAnswer: "",
      answerPlaceHolder: "",
      optionImage: "",
    };
    setOptions([...options, newOption]);
  };

  const deleteOption = (id) => {
    const filteredOptions = options.filter((option) => option.id !== id);
    const deletedOption = options.find((item) => item.id === id);
    const filteredImages = questionOptionImages.filter(
      (item) => Object.keys(item)[0] !== deletedOption.optionImage
    );
    const updatedOptions = filteredOptions.map((option, index) => ({
      ...option,
      answerOption: `Option ${String.fromCharCode(index + 65)}`,
      id : index + 1
    }));
    let imagesData = imageUrls;
    const existingIndex = imagesData.findIndex((images) =>  images.id === id);
    if(existingIndex !== -1){
      imagesData.splice(existingIndex, 1);
    }
    imagesData = imagesData.map((image,index) => ({...image,id:index+1}));
    setImageUrls(imagesData);
    setQuestionOptionImages(filteredImages);
    setOptions(updatedOptions);
  };

  const handleChangeQuestions = (value,name='') => {
    if (name && name === "questionTitle") {
      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        question: value,
      }));
    }
    if (name === "questionDescription") {
      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        description: value,
      }));
    }
    if (name === "questionExplaination") {
      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        explanation: value,
      }));
    }
    if (name === "questionCategory") {
      let questionCategory = value ? JSON.parse(value) : "";
      setSelectedReadingQuestionType(questionCategory);
      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        questionContentTypeId: questionCategory._id,
      }));
    }
    if (name === "questionDifficulty") {
      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        questionDifficultyLevel: value,
      }));
    }
    if (name === "questionTags") {
      let tags = value.split(",").map((tag) => tag.trim());
      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        questionTags: tags,
      }));
    }
  };

  // const handleChangeOptions = (e, id) => {
  //   let value = e.target.value;
  //   if (e.target.name === "text") {
  //     setOptions(
  //       options.map((option) =>
  //         option.id === id
  //           ? {
  //               ...option,
  //               answerPlaceHolder: e.target.value,
  //               optionImage: value.includes(`{${option.answerOption} Image}`)
  //                 ? `{${option.answerOption} Image}`
  //                 : "",
  //             }
  //           : option
  //       )
  //     );
  //   } else if (e.target.name === "radio") {
  //     setOptions(
  //       options.map((option) =>
  //         option.id === id
  //           ? { ...option, isCorrectAnswer: e.target.checked }
  //           : { ...option, isCorrectAnswer: false }
  //       )
  //     );
  //   }
  // };

  const handleChangeOptions = (name,id,value) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) => {
        if (option.id === id) {
          if (name === "text") {
            if (option.answerPlaceHolder === value) return option;
  
            return {
              ...option,
              answerPlaceHolder: value,
              optionImage: value.includes(`{${option.answerOption} Image}`)
                ? `{${option.answerOption} Image}`
                : "",
            };
          } else if (name === "radio") {
            return { ...option, isCorrectAnswer: value };
          }
        }
        return name === "radio" ? { ...option, isCorrectAnswer: false } : option;
      })
    );
  };
  

  const handleSubmit = async (id) => {
    setQuestion((prevQuestion) => ({ ...prevQuestion, answers: options }));
    let tempOptions = [...options].map((item, index) => {
      //delete item.value;
      delete item.id;
      delete item?.optionImage;
      delete item?.uploadedImage;
      delete item?.isEdit;
      return item;
    });

    let finaleQuestions = { ...question };
    questionTitleImages.forEach((itm) => {
      delete itm.isEdit;
      delete itm.isView;
      Object.assign(finaleQuestions.imageDetails, itm);
    });
    questionDescriptionImages.forEach((itm) => {
      delete itm.isEdit;
      delete itm.isView;
      Object.assign(finaleQuestions.imageDetails, itm);
    });
    questionOptionImages.forEach((itm) => {
      Object.assign(finaleQuestions.imageDetails, itm);
    });

    let payload = { ...finaleQuestions, answers: tempOptions, moduleId: currentModuleId,
      sectionId: currentSectionId, };
    setIsLoading(true);
    try {
      let url = `${serviceUrl}/api/sat/questions`;
      let method = 'POST';
      
      if (id) {
        url = `${url}/update?questionId=${id}`;
        method = 'PUT';
      } else {
        url = `${url}/create`;
      }
      
      const response = await axios({
        method: method,
        url: url,
        data: payload,
        headers: genericHeaders(),
      });
      if (response.status === 201) {
        setIsLoading(false);
        setQuestion({
          question: "",
          description: "",
          answers: [],
          explanation: "",
          isActive: true,
          more: {},
          additionalProps: {},
          moduleId: currentModuleId,
          sectionId: currentSectionId,
          questionTypeId: "",
          questionContentTypeId: "",
          questionDifficultyLevel: "",
          imageDetails: {},
          questionTags: [],
        });
        setSelectedReadingQuestionType({});
        setOptions([]);
        setQuestionOptionImages([]);
        toast.success("Question added Successfully");
      }
      if(response.status === 404){
        toast.error("Please Add Question Properly");
      }
      if(response.status === 200){
        setIsLoading(false);
        toast.success("Question Updated Successfully");
      }
      if (response.status === 401) {
        setIsError(true);
        setErrorData({
          statusCode: response.status,
          errorMessage: "Authentication Required",
        });
      }
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
      setErrorData({
        statusCode: e.response.status,
        errorMessage: e.response.data.message,
      });
    }
  };
  const addAnswer = () => {
    let tempQuestion = question.question + ":exp{answer}:exp";
    let tempQuestions = { ...question, question: tempQuestion };
    setQuestion(tempQuestions);
  };

  const addImage = (name) => {
    let tempQuestion;
    let tempQuestions;
    if (name === "title") {
      tempQuestion =
        question.question +
        `{#QuestionTitleImage${questionTitleImages.length + 1}#}`;
      tempQuestions = { ...question, question: tempQuestion };
    }
    if (name === "description") {
      tempQuestion =
        question.description +
        `{#QuestionDescriptionImage${questionDescriptionImages.length + 1}#}`;
      tempQuestions = { ...question, description: tempQuestion };
    }
    setQuestion(tempQuestions);
  };
  const isViewOptionImage = async (optn) => {
    setOptions(
      options.map((option) =>
        option.id === optn.id ? { ...option, isEdit: !option.isEdit } : option
      )
    );
  };



  useEffect(() => {
    let matchedQuestion = questionCategory.find(
      (item) => item.name === questionType
    );
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      questionTypeId: matchedQuestion?._id,
    }));
  }, []);

  const imagesArray = (questionTitle, existingImages,imageDetails) => {
    const regex = /{#([^}]+)#}/g;
    const resultArray = [];
    let match;

    while ((match = regex.exec(questionTitle)) !== null) {
      const key = match[1];
      const existingImage = existingImages.find(
        (img) => Object.keys(img)[0] === key
      );
      if (existingImage) {
        resultArray.push(existingImage);
      } else {
        const obj = {};
      if(Object.keys(imageDetails).length > 0){
        obj[key] = imageDetails[key];
        if(imageDetails[key]){
          obj["isEdit"] = false;
          obj["isView"] = true;
        }
       
      }else{
        obj[key] = "";
      }  
        resultArray.push(obj);
      }
    }

    return resultArray;
  };
const isSubmitDisabled = () => {
  let isDisabled= options.every((item) => !item.isCorrectAnswer);
  setIsUpdate(isDisabled)
 
}

useEffect(() => {
  isSubmitDisabled()
},[options])
  useEffect(() => {
    const questionTitle = question.question;
    const questionDescription = question.description;

    const resultArrayTitleImages = imagesArray(
      questionTitle,
      questionTitleImages,
      ""
    );
    const resultArrayDescriptionImages = imagesArray(
      questionDescription,
      questionDescriptionImages,
      ""
    );

    setQuestionTitleImages(resultArrayTitleImages);
    setQuestionDescriptionImages(resultArrayDescriptionImages);
  }, [question]);

  const fileName = (uploadedImage) => {
    let name = uploadedImage.fileName ?? '';
    return name
  }

  useEffect(() => {
    const fetchReadingQuestionContent = async () => {
      setIsLoading(true);
      const url = `${serviceUrl}/api/sat/question/content/getAll`;
      try {
        const data = await fetch(url, { headers: genericHeaders() });
        const response = await data.json();
        if (data.status === 200) {
          setIsLoading(false);
          if (currentSectionName === "Mathematics") {
            let mathsQuestionTypes = response.filter(
              (item) => item.questionType === "MATHEMATICS"
            );
            setQuestionTypesReading(mathsQuestionTypes);
          } else {
            let readingQuestionTypes = response.filter(
              (item) => item.questionType === "READING_AND_WRITING"
            );
            setQuestionTypesReading(readingQuestionTypes);
          }
        }
        if (data.status === 401) {
          setIsError(true);
          setErrorData({
            statusCode: data.status,
            errorMessage: "Authentication Required",
          });
        }
      } catch (e) {
        setIsLoading(false);
        setIsError(true);
        setErrorData({
          statusCode: e.response.status,
          errorMessage: e.response.data.message,
        });
        console.log(e, "Fetching Reading Question Content");
      }
    };
    fetchReadingQuestionContent();
  }, []);

  useEffect(() => {
    const getEditQuestionDetails = async (qId) => {
      setIsLoading(true);
      try {
        let url = `${serviceUrl}/api/sat/questions/getquestionbyid?questionId=${qId}`;
        const data = await fetch(url, { headers: genericHeaders() });     
        const response = await data.json();
        if (data.status === 200) {
          let tagsPolish = response?.questionTags?.join(",");
          let optionsCopy = response?.answers;
          optionsCopy && optionsCopy.length > 0 && optionsCopy.forEach(async (itm, ind) => {
            const regexPattern = /\{(.+?)\}/;
            let answerDescr = itm?.answerPlaceHolder;
            let matchPattern = answerDescr?.match(regexPattern);
            if (matchPattern && matchPattern.length > 1) {
              const extractedText = matchPattern[1].trim();
              itm["optionImage"] = response?.imageDetails?.[extractedText];
              itm["uploadedImage"] = response?.imageDetails?.[extractedText];
            }

            itm["id"] = ind + 1;
            if(itm?.optionImage?.fileName){
            const file_url = await GetFile(itm?.optionImage?.fileName);
            let images = [...imageUrls];
            let imageData = {
              id: ind + 1,
              imageUrl: file_url,
            };
            images.push(imageData);
            setImageUrls(images);
            }
          });

          let resultArrayTitleImages = imagesArray(
            response?.question,
            questionTitleImages,
            response?.imageDetails
          );
          let resultArrayDescriptionImages = imagesArray(
            response?.description,
            questionDescriptionImages,
            response?.imageDetails
          );
          setQuestionTitleImages(resultArrayTitleImages);
          setQuestionDescriptionImages(resultArrayDescriptionImages);
          setOptions(optionsCopy);
          setQuestion((question) => ({
            ...question,
            question: response?.question,
            description: response?.description,
            questionDifficultyLevel: response?.questionDifficultyLevel,
            questionTags: response?.questionTags,
            explanation: response?.explanation,
            questionContentTypeId: response?.questionContentTypeId,
          }));
          setSelectedReadingQuestionType(response?.questionContentTypeDetails);
          setIsLoading(false);
        }
        if (data.status === 401) {
          setIsError(true);
          setErrorData({
            statusCode: data.status,
            errorMessage: "Authentication Required",
          });
        }
      } catch (e) {
        console.log(e,"ERROORRRR")
        setIsLoading(false);
        setIsError(true);
        setErrorData({
          statusCode: e.response?.status,
          errorMessage: e.response?.data?.message,
        });
        console.log(e, "Fetching Edit Question Content");
      }
    };
    if (id) {
      getEditQuestionDetails(id);
    }
  }, [id]);

  const isShortAnswerExists = (question,questionType) => {

    if(questionType === 'Short Answer'){
      const regex = /\{[^}]+\}/;
    return !regex.test(question.question);
    }

  }

  const getImageUrl = (optionId) => {
    const image = [...imageUrls];
    const imageData = image.find((image) => image.id === optionId);
    if(imageData?.imageUrl){
      return imageData.imageUrl;
    }
    return '';
  }

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center min-h-screen">
          <RotatingLines
            visible={true}
            strokeColor="blue"
            height="96"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : isError ? (
        <ErrorPage
          statusCode={errorData.statusCode}
          message={errorData.errorMessage}
        />
      ) : (
        <div>
          <button
            className="hover:bg-slate-500 hover:text-white rounded-md flex items-center bg-gray-300 py-2 px-6 mb-4"
            onClick={() => {
              if (currentSectionName === "ReadingAndWriting") {
                navigate("/satReading");
              }
              if (currentSectionName === "Mathematics") {
                navigate("/satMaths");
              }

              setSectionId(currentSectionId);
              setModuleId(currentModuleId);
            }}
          >
            <IoMdArrowBack /> <span className="ml-1">Back</span>
          </button>
          <div className="flex text-lg pt-2">
            {currentSectionName}{" "}
            <span className="flex items-center">
              <IoIosArrowForward />{" "}
            </span>
            {currentModuleName}{" "}
            <span className="flex items-center">
              <IoIosArrowForward />{" "}
            </span>{" "}
            {questionType}
          </div>
          <div className="border border-gray-400 p-6 rounded-md pt-2 ">
            <div className="flex justify-between">
              <label className="text-[#4E5664] flex items-center">
                Question Title <span className="text-red-600">*</span> :
              </label>
              {questionType === "Short Answer" && (
                <button
                  className="border border-[#184ee3] text-[#184ee3] rounded-full my-2 px-4 py-1 mr-4"
                  onClick={addAnswer}
                >
                  Add Answer
                </button>
                // <FaPlus className="cursor-pointer flex items-center" onClick={addAnswer} />
              )}
              <button
                className="border border-[#184ee3] text-[#184ee3] rounded-full my-2 px-4 py-1 mr-4"
                onClick={() => addImage("title")}
              >
                Add Image
              </button>
            </div>

            {/* <textarea
              className="py-3 my-2 w-full px-4 outline-none border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              rows="2"
              cols="50"
              name="questionTitle"
              onChange={handleChangeQuestions}
              value={question.question}
            >
              {" "}
            </textarea> */}
            <ReactQuill
              className="min-h-52 py-3 my-2 w-full px-4 outline-none border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              placeholder="Question"
              value={question.question || ""}
              onChange={(value) =>
                handleChangeQuestions(value, "questionTitle")
              }
              modules={modules}
              formats={formats}
            />
            {questionTitleImages?.map((item, index) => {
              return (
                <>
                  <UploadImage
                    indexTitleImage={index}
                    item={item}
                    questionTitleImages={questionTitleImages}
                    setQuestionTitleImages={setQuestionTitleImages}
                  />
                </>
              );
            })}

            {currentSectionName === "Mathematics" && question.question && (
              <div className="border border-gray-400 px-5 rounded-md pt-2  my-6">
                <div className="flex flex-col p-2">
                  <span className="flex my-2">
                    {/* <span className="mt-2">Your Maths Questions is: </span> &nbsp;
                &nbsp;{" "} */}
                    <div className="flex">
                      {renderHtmlWithTeX(question.question)}{" "}
                    </div>
                  </span>
                </div>
              </div>
            )}
            <div className="flex justify-between">
              <label className="text-[#4E5664] py-1">
                Question Description:
              </label>
              <button
                className="border border-[#184ee3] text-[#184ee3] rounded-full my-2 px-4 py-1 mr-4"
                onClick={() => addImage("description")}
              >
                Add Image
              </button>
            </div>
            <ReactQuill
              className="min-h-52 py-3 my-2 w-full px-4 outline-none border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              placeholder="Description"
              value={question.description || ""}
              onChange={(value) =>
                handleChangeQuestions(value, "questionDescription")
              }
              modules={modules}
              formats={formats}
            />
            {questionDescriptionImages?.map((item, index) => (
              <UploadImage
                indexTitleImage={index}
                item={item}
                questionTitleImages={questionDescriptionImages}
                setQuestionTitleImages={setQuestionDescriptionImages}
              />
            ))}

            {currentSectionName === "Mathematics" && question.description && (
              <div className="border border-gray-400 px-5 rounded-md pt-2  my-6">
                <div className="flex flex-col p-2">
                  <span className="flex my-2">
                    {/* <span className="mt-2">Your Maths Questions is: </span> &nbsp;
                &nbsp;{" "} */}
                    <div className="flex flex-col">
                      {renderHtmlWithTeX(question.description)}{" "}
                    </div>
                  </span>
                </div>
              </div>
            )}
            <div>
              <label className="text-[#4E5664] py-1 mr-2">
                Question Category <span className="text-red-600">*</span> :
              </label>
              <select
                name="questionCategory"
                className="py-2 px-4 bg-white border border-gray-200 rounded-lg text-base outline-none focus:border-blue-500 focus:ring-blue-500"
                value={
                  selectedReadingQuestionType
                    ? JSON.stringify(selectedReadingQuestionType)
                    : ""
                }
                onChange={(e) =>
                  handleChangeQuestions(e.target.value, "questionCategory")
                }
              >
                <option value="">Select Question Category</option>
                {questionTypesReading.length > 0 &&
                  questionTypesReading?.map((item) => (
                    <option
                      key={item._id}
                      value={JSON.stringify(item)}
                    >{`${item.name}`}</option>
                  ))}
              </select>
              {selectedReadingQuestionType && (
                <div className="flex">
                  <label className="text-[#4E5664] py-1 mr-2 my-3">
                    Question Content Category:
                  </label>
                  <pre className="flex font-semibold items-center">
                    {selectedReadingQuestionType?.questionContentDomain}
                  </pre>
                </div>
              )}
            </div>
            <div className="my-2">
              <label className="text-[#4E5664] py-1 mr-2">
                Question Difficulty:
              </label>
              <select
                name="questionDifficulty"
                value={question.questionDifficultyLevel}
                onChange={(e) =>
                  handleChangeQuestions(e.target.value, "questionDifficulty")
                }
                className="py-2 px-4 bg-white border outline-none focus:border-blue-500 focus:ring-blue-500 border-gray-200 rounded-lg text-base"
              >
                <option value="">Select Difficulty</option>
                <option value="621">Easy</option>
                <option value="622">Medium</option>
                <option value="623">Hard</option>
                <option value="624">Untagged</option>
              </select>
            </div>
            <label className="text-[#4E5664] py-1">Tags:</label>
            <input
              type="text"
              name="questionTags"
              value={question?.questionTags}
              onChange={(e) =>
                handleChangeQuestions(e.target.value, "questionTags")
              }
              className="py-3 my-2 w-full px-4 outline-none border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
            />
            <label className="text-[#4E5664] py-1">
              Explaination <span className="text-red-600">*</span> :
            </label>
            <ReactQuill
              className="min-h-52 py-3 my-2 w-full px-4 outline-none border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              placeholder="Explanation"
              value={question.explanation || ""}
              onChange={(value) =>
                handleChangeQuestions(value, "questionExplaination")
              }
              modules={modules}
              formats={formats}
            />
            {currentSectionName === "Mathematics" && question.explanation && (
              <div className="border border-gray-400 px-5 rounded-md pt-2  my-6 w-auto">
                <div className="flex flex-col p-2">
                  <span className="flex my-2">
                    {/* <span className="mt-2">Your Maths Questions is: </span> &nbsp;
                &nbsp;{" "} */}
                    <div className="flex flex-col">
                      {renderHtmlWithTeX(question?.explanation)}{" "}
                    </div>
                  </span>
                </div>
              </div>
            )}
            {questionType === "Multiple choice-Single Answer" && (
              <button
                className="border border-[#184ee3] text-[#184ee3] rounded-full my-2 px-4 py-1 mr-4"
                onClick={addOption}
              >
                Add Option
              </button>
            )}
            {options.length > 0 &&
              options?.map((option) => (
                <div
                  key={option.id}
                  className="my-8 border border-gray-500 p-4 rounded-lg"
                >
                  <div className="flex items-center my-1">
                    <label className="text-[#4E5664] ">
                      {option.answerOption}
                    </label>{" "}
                    <button
                      className="border border-[#184ee3] text-[#184ee3] rounded-full my-2 px-4 py-1 mx-6 mr-4"
                      onClick={() => addOptionImage(option)}
                    >
                      Add {option.answerOption} Image
                    </button>
                    <FaTrash
                      onClick={() => deleteOption(option.id)}
                      className="text-red-600 cursor-pointer ml-4"
                    />
                  </div>
                  {/* <input
                    className="py-3 w-1/2 px-4  border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    type="text"
                    name="text"
                    value={option.answerPlaceHolder}
                    onChange={(e) =>
                      handleChangeOptions(e, option.id, e.target.value)
                    }
                  /> */}
                  <div className="flex items-center">
                    <ReactQuill
                      className="max-h-50 py-3 my-2 w-2/3 px-4 outline-none border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      value={option.answerPlaceHolder || ""}
                      onChange={(val) =>
                        handleChangeOptions('text', option.id, val)
                      }
                      modules={modules}
                      formats={formats}
                    />
                    <div>
                      <input
                        type="radio"
                        name="radio"
                        checked={option.isCorrectAnswer}
                        className="ml-3"
                        onChange={(e) =>
                          handleChangeOptions('radio', option.id, e.target.checked)
                        }
                      />{" "}
                      <label className="text-[#4E5664] ">Correct Answer</label>
                    </div>
                  </div>
                  {currentSectionName === "Mathematics" &&
                    option.answerPlaceHolder && (
                      <span className="flex w-1/2 my-2 border border-gray-400 rounded-lg p-2">
                        {/* <span className="mt-2">Your Maths Questions is: </span> &nbsp;
                &nbsp;{" "} */}
                        <div className="flex">
                          {renderHtmlWithTeX(option.answerPlaceHolder)}{" "}
                        </div>
                      </span>
                    )}
                  
                  {option.optionImage && (
                    <div className="my-2">
                      <div className="flex mt-1 mb-3 mx-4 items-center">
                        <label>{option.answerOption}</label>
                        <input
                          className="mx-4 "
                          type="file"
                          ref={inputRef}
                          onChange={(event) =>
                            handleOptionsImage(event, option)
                          }
                        />
                        {/* <button
                          className="bg-blue-400 text-white px-2 py-1 rounded mx-4"
                          onClick={onChooseFile}
                        >
                          Choose File
                        </button> */}
                        {option.uploadedImage && (
                          <span className="mx-2">
                            {fileName(option.uploadedImage)}
                          </span>
                        )}
                        {option.uploadedImage && (
                          <div onClick={() => isViewOptionImage(option)}>
                            {option.isEdit ? (
                              <>
                                <button className="border border-[#184ee3] text-[#184ee3]  items-center rounded-full my-2 px-4 py-1 mr-4">
                                  Close
                                </button>
                              </>
                            ) : (
                              <GrView className="text-blue-500 text-xl cursor-pointer items-center" />
                            )}
                          </div>
                        )}
                      </div>
                      {option.uploadedImage && (
                        <div>
                          {" "}
                          {option.isEdit && (
                            <img src={getImageUrl(option.id)} alt="cms" />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            {(options?.length || questionType === "Short Answer") > 0 && (
              <>
                {id ? (
                  <button
                    className={`border border-[#184ee3] text-[#184ee3] rounded-full  px-4 py-1 cursor-pointer`}
                    // disabled={isUpdate}
                    onClick={() => {
                      handleSubmit(id);
                      if (currentSectionName === "ReadingAndWriting") {
                        navigate("/satReading");
                      }
                      if (currentSectionName === "Mathematics") {
                        navigate("/satMaths");
                      }
                    }}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className={`border border-[#184ee3] text-[#184ee3] rounded-full  px-4 py-1 ${
                      (isUpdate && questionType !== "Short Answer") ||
                      !question.question ||
                      !question.explanation ||
                      Object.keys(selectedReadingQuestionType).length <= 0 ||
                      isShortAnswerExists(question, questionType)
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={() => {
                      handleSubmit("");
                      if (currentSectionName === "ReadingAndWriting") {
                        navigate("/satReading");
                      }
                      if (currentSectionName === "Mathematics") {
                        navigate("/satMaths");
                      }
                    }}
                    disabled={
                      (isUpdate && questionType !== "Short Answer") ||
                      !question.question ||
                      !question.explanation ||
                      Object.keys(selectedReadingQuestionType).length <= 0 ||
                      isShortAnswerExists(question, questionType)
                    }
                  >
                    Submit
                  </button>
                )}
                <button
                 className={`border border-[#184ee3] text-[#184ee3] rounded-full ml-2 px-4 py-1 cursor-pointer`}
                 onClick={() => setIsView(true)}
                >Preview</button>
              </>
            )}
          </div>
          <Modal 
            isOpen={isView} 
            width="max-w-[90%]"
            onClose={() => {
              setIsView(false)
            }
          }>
              <div className="py-6 px-3 flex flex-col rounded-md border">
                {id && (<p><span className="font-semibold text-gray-800">Question Id:</span> {id}</p>)}
                <div className="flex w-full h-96 overflow-y-auto border rounded-lg">
                  <div className="w-1/2 p-5 border-r-2">
                    <p className="font-semibold text-gray-800">Description:</p>
                    {question.description ? renderHtmlWithTeX(question.description) : <p>No Description</p>}
                  </div>
                  <div className="w-1/2 p-5">
                    <p className="font-semibold text-gray-800">Question:</p> 
                    {question.question && renderHtmlWithTeX(question.question)}
                    {options.length > 0 && (
                      <div className="mt-2 space-y-2">
                        {options.map((opt) => (
                          <div key={opt.answerOption} className="flex items-center border rounded-md w-full p-1">
                            <span className="font-semibold text-gray-800">{opt.answerOption}</span>
                            <span className="ml-2">{renderHtmlWithTeX(opt.answerPlaceHolder)}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-2 w-full h-60 p-5 overflow-y-auto border rounded-lg">
                  
                  <div className="">
                    <span className="font-semibold text-gray-800">Explanation:</span> {question?.explanation && renderHtmlWithTeX(question?.explanation)}
                  </div>
                </div>
              </div>
          </Modal>
        </div>
        
      )}
    </>
  );
});

export default MultipleChoiceQuestion;
