import { useState, useEffect } from "react";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import CustomToolTip from "../Common/CustomToolTip";
import { useNavigate } from "react-router-dom";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import { observer } from "mobx-react-lite";
import axios from "axios";
import writingStore from "../../Services/Stores/writingStore";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

const TaskTwo = observer(() => {
  const navigate = useNavigate();
  const { questionsPartTwo, selectedSectionId } = writingStore;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const limit = 10;

  const renderQuestions = async () => {
    try {
      setLoading(true);
      const url = `${serviceUrl}/api/writing/questions/fetch?sectionId=${selectedSectionId}&search=${searchQuery}&page=${currentPage}&limit=${limit}`;
      const response = await axios.get(url, { headers: genericHeaders() });
      if (response.status === 200) {
        setLoading(false);
        let formattedData = JSON.parse(JSON.stringify(response));
        writingStore.setQuestionsPartTwo(formattedData);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    renderQuestions();
  }, [currentPage, searchQuery]); 

  const deletePartTwoQuestion = (id) => {
    setItemToDelete(id);
    setIsDialogOpen(true);
  };

  const confirmDelete = async () => {
    setIsDialogOpen(true);
    try {
      const url = `${serviceUrl}/api/writing/questions/remove?questionId=${itemToDelete}`;
      const response = await axios.delete(url, { headers: genericHeaders() });
      if (response.status === 200) {
        setIsDialogOpen(false);
        toast.success("Question Deleted Successfully");
        renderQuestions();
      }
    } catch (error) {
      setIsDialogOpen(false);
      setItemToDelete(null);
      toast.error("Something Went Wrong");
      console.error("Error deleting question:", error);
    }
  };

  const cancelDelete = () => {
    setIsDialogOpen(false);
    setItemToDelete(null);
  };

  const indexOfLastItem = currentPage * limit;
  const indexOfFirstItem = indexOfLastItem - limit;
  const filteredItems = questionsPartTwo?.data?.data?.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const totalPages = questionsPartTwo?.data?.lastPageNumber;

  return (
    <div>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} 
          className="border p-2 rounded w-1/2"
        />
      </div>
      {isDialogOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-md">
            <h2 className="text-xl mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this item?</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={cancelDelete}
                className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white py-2 px-4 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <RotatingLines
            visible={true}
            strokeColor="red"
            height="96"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <table className="mt-4">
            <thead>
              <tr className="bg-[#F8F9FA] text-[#737E93] text-sm font-semibold">
                <td>S.No</td>
                <td>Name</td>
                <td>Task</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {filteredItems && filteredItems.length > 0 ? (
                filteredItems.map((item, index) => (
                  <tr key={item.id}>
                    <td>{indexOfFirstItem + index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.task}</td>
                    <td>
                      <div className="flex justify-center">
                        <div>
                          <button
                            data-tooltip-id={`edit-tooltip-${index}`}
                            className="mr-2"
                            onClick={() => {
                              writingStore.setUpdatedData(item);
                              writingStore.savePersistedData();
                              navigate(`/createWritingPart2/${item?.id}`);
                            }}
                          >
                            <BiEdit className="text-xl" />
                          </button>
                          <CustomToolTip
                            content={"Edit"}
                            id={`edit-tooltip-${index}`}
                            place={"bottom"}
                          />
                        </div>
                        <div>
                          <button
                            data-tooltip-id={`delete-tooltip-${index}`}
                            className="mr-2"
                            onClick={() => {
                              deletePartTwoQuestion(item?.id);
                            }}
                          >
                            <RiDeleteBin5Line className="text-red-500 text-xl" />
                          </button>
                          <CustomToolTip
                            content={"Delete"}
                            id={`delete-tooltip-${index}`}
                            place={"bottom"}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No items found.</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="flex justify-center mt-4">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-3 py-1 mx-1 ${
                currentPage !== 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-black"
              }  rounded`}
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, i) => (
              <button
                key={i + 1}
                onClick={() => setCurrentPage(i + 1)} 
                className={`px-3 py-1 mx-1 ${
                  currentPage === i + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300"
                } rounded`}
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)} 
              disabled={currentPage === totalPages}
              className={`px-3 py-1 mx-1 ${
                currentPage !== totalPages
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-black"
              }  rounded`}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
});

export default TaskTwo;
